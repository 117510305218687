import { useStaticQuery, graphql } from 'gatsby';
import { SupportedLanguages } from 'models/site';
import { GroupItem } from 'models/query';

interface CategoryQueryResult {
  allMarkdownRemark: {
    group: Array<GroupItem<{ fields: { lang: SupportedLanguages } }>>;
  };
}
export const useAllCategories = (lang: SupportedLanguages = 'ko') => {
  const {
    allMarkdownRemark: { group },
  } = useStaticQuery<CategoryQueryResult>(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___categories) {
          fieldValue
          totalCount
          nodes {
            fields {
              lang
            }
          }
        }
      }
    }
  `);

  /** @TODO 현재 랭귀지에 속한 포스팅만 totalCount에 포함되도록 수정 */
  const currentLanguegeCategories = group.filter(
    ({ nodes }) => nodes.filter(({ fields }) => fields.lang === lang).length > 0
  );

  return currentLanguegeCategories.map(({ fieldValue, totalCount }) => ({ fieldValue, totalCount }));
};
