import React, { useCallback } from 'react';
import WidgetCard from 'components/Cards/Widgets';
import { Link } from 'gatsby';
import { Chip, List, ListItem, ListItemText, ListItemSecondaryAction, makeStyles, Theme } from '@material-ui/core';
import { useAllCategories } from 'hooks/useAllCategories';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';
import { usePageRouteUtils } from 'hooks/usePageRouteUtils';
import { useAllPosts } from 'src/hooks/useAllPosts';

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  item: {
    listStyle: 'none',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius / 2,
    overflow: 'hidden',
  },
  chip: {
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.6rem',
    height: 'auto',
    padding: '0.3rem 0',
    backgroundColor: theme.palette.grey['200'],
  },
}));

interface Props {
  onClickCategory?: (category: string) => void;
}
const CategoriesCard = ({ onClickCategory }: Props) => {
  const styles = useStyles();
  const [lang] = useRecoilState(languageAtom);
  const categories = useAllCategories(lang);
  const posts = useAllPosts();
  const { getCategoryPath } = usePageRouteUtils();

  const handleCategoryClick = useCallback(
    (category: string) => {
      onClickCategory?.(category);
    },
    [onClickCategory]
  );

  return (
    <WidgetCard title="카테고리">
      <List component="nav" dense={true}>
        <Link to="/" className={styles.link}>
          <ListItem button className={styles.item} onClick={() => handleCategoryClick('전체')}>
            <ListItemText>전체</ListItemText>
            <ListItemSecondaryAction>
              <Chip className={styles.chip} label={posts.length} size="small" />
            </ListItemSecondaryAction>
          </ListItem>
        </Link>
        {categories.map(({ fieldValue, totalCount }, index) => (
          <Link key={index} to={getCategoryPath(fieldValue)} className={styles.link}>
            <ListItem button className={styles.item} onClick={() => handleCategoryClick(fieldValue)}>
              <ListItemText>{fieldValue}</ListItemText>
              <ListItemSecondaryAction>
                <Chip className={styles.chip} label={totalCount} size="small" />
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
        ))}
      </List>
    </WidgetCard>
  );
};

export default CategoriesCard;
