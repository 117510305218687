import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Link } from 'gatsby';
import { useCallback, useMemo } from 'react';
import { useSiteMetadata } from 'hooks/useSiteMetadata';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  root: {
    marginTop: spacing(4),
    '& .MuiPaginationItem-page.Mui-selected': {
      color: palette.common.white,
    },
    '& .MuiPaginationItem-page.Mui-disabled': {
      visibility: 'hidden',
    },
  },
}));

interface Props {
  pageCount: number;
  currentPage: number;
  defaultUrl?: string;
  lang: string;
}
const Paginator = ({ pageCount, currentPage, defaultUrl = '/', lang }: Props) => {
  const styles = useStyles();
  const { defaultLanguage } = useSiteMetadata();
  const pageLanguage = useMemo(() => (lang === defaultLanguage ? '' : lang), [defaultLanguage, lang]);

  const getLink = useCallback(
    (page: number) => {
      return page === 1 ? `${defaultUrl}${pageLanguage}` : `${defaultUrl}page/${page}/${pageLanguage}`;
    },
    [defaultUrl, pageLanguage]
  );

  return (
    <Box display="flex" justifyContent="center" className={styles.root}>
      <Pagination
        count={pageCount}
        page={currentPage}
        color="primary"
        size="large"
        renderItem={item => <PaginationItem component={Link} to={getLink(item.page)} {...item} />}
      />
    </Box>
  );
};

export default Paginator;
