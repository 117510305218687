import React, { useMemo, useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import CategoriesCard from 'components/Cards/Widgets/Categories';
import Paginator from 'components/Utils/Paginator';
import { generateLogger } from 'utils/loggers';
import { Box, makeStyles, Grid } from '@material-ui/core';
import { PostListPageQueryResult } from 'models/query';
import RowList from 'components/Lists/RowList';
import { Link } from 'gatsby';
import PostIntroCard from 'components/Cards/Posts';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';

const logger = generateLogger('tag_page');
const handleCategoryClick = (categoryName: string) => {
  logger.click('click_category', { categoryName });
};
const handlePostClick = (path: string) => {
  logger.click('click_posting', { path });
};

const useStyles = makeStyles({
  items: {
    transition: 'transform 0.2s ease-in-out',
    '& a': {
      textDecoration: 'none',
    },
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
});

interface Props extends PostListPageQueryResult {
  location: Location;
  pageContext: {
    tag: string;
    totalCount: number;
  };
}
const TagPage = ({ data, location, pageContext: { tag, totalCount } }: Props) => {
  const styles = useStyles();
  const {
    allMarkdownRemark: { edges, pageInfo },
  } = data;
  const [, tagPath, tagSlug] = location.pathname.split('/');
  const path = `/${tagPath}/${tagSlug}`;
  const posts = useMemo(() => edges.map(p => p.node), [edges]);
  const [lang] = useRecoilState(languageAtom);

  useEffect(() => {
    logger.view();
  }, []);

  return (
    <Layout
      title={<Layout.Title title={tag} description={`총 ${totalCount}개의 포스팅`} />}
      right={
        <Box>
          <CategoriesCard onClickCategory={handleCategoryClick} />
        </Box>
      }
    >
      <SEO title={tag} />
      <RowList>
        {posts.map(p => (
          <Grid item xs={12} key={p.frontmatter.title} className={styles.items}>
            <Link to={p.fields.path} onClick={() => handlePostClick(p.fields.path)}>
              <PostIntroCard
                title={p.frontmatter.title ?? ''}
                description={p.excerpt ?? ''}
                thumbnail={p.frontmatter.thumbnail.childImageSharp?.fluid?.src ?? ''}
                date={p.frontmatter.date}
              />
            </Link>
          </Grid>
        ))}
      </RowList>
      <Paginator pageCount={pageInfo.pageCount} currentPage={pageInfo.currentPage} defaultUrl={path} lang={lang} />
    </Layout>
  );
};

export default TagPage;
