import { useSiteMetadata } from 'hooks/useSiteMetadata';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';
import kebabCase from 'lodash/kebabCase';
import { useCallback, useMemo } from 'react';

const combinePath = (path: string, variable: string, languagePath: string) => {
  return `${path}/${kebabCase(variable)}${languagePath}`;
};
export const usePageRouteUtils = () => {
  const { defaultLanguage } = useSiteMetadata();
  const [lang] = useRecoilState(languageAtom);
  const languagePath = useMemo(() => {
    return lang === defaultLanguage ? '' : `/${lang}`;
  }, [lang, defaultLanguage]);

  const getCategoryPath = useCallback(
    (category: string) => {
      return combinePath('/categories', category, languagePath);
    },
    [languagePath]
  );

  const getTagPath = useCallback(
    (tag: string) => {
      return combinePath('/tags', tag, languagePath);
    },
    [languagePath]
  );

  return {
    getCategoryPath,
    getTagPath,
  };
};
